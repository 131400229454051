import React, { useState, useEffect } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserMessageBridge from "../../components/Auth/UserMessageBridge";

const AddACar = () => {
  const { t } = useTranslation();
  const items = ApiHook.CallRepurchaseItems();
  const [showIframe, setShowIframe] = useState(false);  // ✅ State for toggling iframe
  const handleRequestQuotesClick = () => {
    setShowIframe(true);  // Enable iframe view
  };
  const [dashboardCheck, setDashboardCheck] = useState(false);
  const [userId, setUserId] = useState(null);
  const rightSection = ApiHook.CallDashboardRight(dashboardCheck, setDashboardCheck);

  // Fetch full user payload once from the API
  useEffect(() => {
    if (rightSection?.data?.userProfile) {
      const fullPayload = rightSection.data.userProfile;
      setUserId(fullPayload.userId);
      console.log("📩 UserMessageBridge fetched full user payload:", fullPayload);
    }
  }, [rightSection]);
  console.log("🛒 items?.data:", items?.data);

  return (
    <>
      {/* This is the Breadcrumb */}
      <div className="page_head_top">{t("shopping")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4 text-end">
            <div className="dropdown btn-group top_right_pop_btn_position">
              <Link className="top_righ_pop_btn" onClick={handleRequestQuotesClick}>
                {t("request_quotes")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Conditionally render iframe or product list */}
      {showIframe ? (
        <UserMessageBridge userId={userId} />
      ) : (
        <ProductLists products={items?.data} />  // Default view
      )}
    </>
  );
};

export default AddACar;
