import React from "react";

const UserMessageBridge = (user) => {
    // // Listen for postMessage events requesting user info
    // useEffect(() => {
    //     const handleIncomingMessage = (event) => {
    //         if (event.origin !== window.location.origin) return; // Security check

    //         const { type } = event.data;
    //         console.log("📩 UserMessageBridge received message:", type);

    //         if (type === "REQUEST_USER_INFO" && userInfo) {
    //             // Send full user info back to the sender
    //             event.source.postMessage(
    //                 { type: "USER_INFO_RESPONSE", payload: userInfo },
    //                 event.origin
    //             );
    //             console.log("📤 UserMessageBridge sent full user payload:", userInfo);
    //         }
    //     };

    //     window.addEventListener("message", handleIncomingMessage);
    //     return () => window.removeEventListener("message", handleIncomingMessage);
    // }, [userInfo]);

    // Construct the URL using the environment variable
    const { userId } = user;
    let iframeUrl = null;

    if (userId) {
        iframeUrl = `${process.env.REACT_APP_REGISTRATION_APP_URL}workflow/add-vehicle/${userId}`;
    }
    console.log("iframeUrl", iframeUrl);

    return (
        iframeUrl && (
            <div className="shoping_page_section">
                <div style={{ width: "100%", height: "100vh", border: "none" }}>
                    <iframe
                        src={iframeUrl}
                        style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            overflow: "hidden",
                        }}
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        )
    );
};

export default UserMessageBridge;
