import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { API_BASE_URL, STRIPE_PRODUCTS } from "../../config/config";
import DatePickerComponent from "../Common/DatePickerComponent";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import MyPayPalOrderButton from "../payment/PaypalOrderButton";
import Skeleton from "react-loading-skeleton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Stripe from "../../views/payment/Stripe";
import { toast } from "react-toastify";
import QuoteModal from "../Register/QuoteModal";
import codeimage from "../../assests/images/pin-code_2803849.png";
import invoiceImage from "../../assests/images/dollar_3816189.png";
import CurrencytoWords from "../../utils/currencyNumberToWords";
import pdfFile from "../../assests/docs/Affiliate_Agreement_1TeamForce.pdf";
import compensation from "../../assests/docs/Affiliate_Compensation_1TeamForce.pdf";
import tesla from "../../assests/docs/One_Llama_Tesla_Bonus_Promotion_Draft.pdf";
import procedures from "../../assests/docs/Policies_and_Procedures_1TeamForce.pdf";
import termsandconditions from "../../assests/docs/termsandconditions_mt_hm_013025.pdf";
import { useNavigate, Link } from "react-router-dom";
import labels from '../../locales/en/en_register.json';

const ReplicaRegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  handleTogglePartialAmntModal,
  animateStepTransition,
  setregSteps,
  data,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [showQuoteModal, setshowQuoteModal] = useState(false);
  const [states, setStates] = useState([]);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [file, setFile] = useState(null);
  const [selectedRegMethod, setselectedRegMethod] = useState("");
  const [selectedDob, setSelectedDob] = useState(dayjs());
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [stripePublicKey, setStripePublicKey] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [stripeIntentStatus, setStripeIntentStatus] = useState("");
  const [requestQuoteStatus, setrequestQuote] = useState(false);
  const dateOfBirthField = data?.contactInformation?.contactField?.find(
    (item) => item.code === "date_of_birth"
  );
  const ageLimit = dateOfBirthField?.options[0]?.validation.ageLimit;
  const [hasMounted, setHasMounted] = useState(false);
  const [isMinAge, setIsMinAge] = useState({
    status: false,
    ageLimit: ageLimit,
  });
  const [isMaxAge, setIsMaxAge] = useState({
    status: false,
    ageLimit: 120,
  });
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const regAmount = useSelector(
    (state) => state?.dashboard?.appLayout?.configuration?.regAmount
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const sponsorImage = useSelector(
    (state) => state.replica?.sponsor?.userDetails?.image
  );

  const {
    register,
    setValue,
    trigger,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm();
  const phoneUtil = PhoneNumberUtil.getInstance();
  const formValues = watch();
  // console.log(formValues);
  // console.log(errors,"error");

  const steps = data?.registrationSteps;

  // ------------------------------------------- API ------------------------------------------
  const deleteBankRecipt = ApiHook.CallReplicaBankRecieptDelete(
    setSubmitButtonActive,
    setValue,
    setFileResponse,
    setFile
  );
  const checkUsernameField = ApiHook.CallReplicaFieldCheck();
  const checkEmailField = ApiHook.CallReplicaFieldCheck();
  const Upload = ApiHook.CallReplicaBankRecieptUpload(
    "register/replica",
    formValues?.username,
    data?.sponsorData?.id,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );
  const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();
  const stripeIntent = ApiHook.CallReplicaCreateStripeIntent();
  const updateStripeIntent = ApiHook.CallReplicaUpdateStripeIntent();
  const cancelStripeIntent = ApiHook.CallReplicaCancelStripeIntent();
  // const checkCoupn = ApiHook.CallcheckCoupanValidOrNot(
  //   formValues?.invitation_code
  // );
  const [billingType, setBillingType] = useState("installment");
  // ---------------------------------- Functions ------------------------------------
  const maxAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const maxAge = today.subtract(ageLimit, "year"); // Calculate the max Age
      return !selectedDate.isAfter(maxAge);
    } else {
      return false;
    }
  };
  const genderOptions = (data) => {
    return data.map((item) => ({
      value: item.value,
      label: t(item.title),
    }));
  };
  const countryList = (data) => {
    return data.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  };
  const stateList = (data) => {
    if (!formValues?.country) {
      return []; // Return an empty array if there's no country specified.
    }

    const selectedCountry = data.find(
      (country) => country.id === Number(formValues.country)
    );

    if (!selectedCountry) {
      return []; // Return an empty array if the selected country is not found.
    }

    return selectedCountry.States.map((state) => ({
      value: state.id,
      label: state.name,
    }));
  };
  const changeCountry = (country) => {
    setSelectedCountry(country?.value);
    setSelectedState(null); // Clear the selected state
    setValue("state", null);
    setValue("country", country?.value); // Update the form value for country
  };
  const handleDateChange = (newDate, item) => {
    if (newDate) {
      setIsMinAge({
        status: minAgeValidation(
          newDate,
          item.options[0]?.validation?.ageLimit
        ),
        ageLimit: item.options[0]?.validation?.ageLimit,
      });
      setIsMaxAge({
        status: maxAgeValidation(newDate, 120),
        ageLimit: 120,
      });
      setSelectedDob(newDate);
      const formattedDate = newDate.format("YYYY-MM-DD");
      setValue("date_of_birth", formattedDate);
      setError("date_of_birth", { message: "" });
    }
  };
  const handleDeleteBankReciept = () => {
    const data = {
      filepath: formValues?.bankReceipt,
      type: "replica",
    };
    if (formValues?.bankReceipt) {
      deleteBankRecipt.mutateAsync(data);
    }
  };
  const openCalender = () => {
    setIsCalenderOpen(true);
  };
  const closeCalender = () => {
    setIsCalenderOpen(false);
  };
  const genderChange = (gender) => {
    setSelectedGender(gender);
    setValue("gender", gender?.value);
  };
  const changeState = (state) => {
    setSelectedState(state?.value);
    setValue("state", state?.value);
  };
  const minAgeValidation = (selectedDate, ageLimit) => {
    if (selectedDate) {
      const today = dayjs();
      const minAge = today.subtract(ageLimit, "year"); // Calculate the minimum Age
      return selectedDate.isBefore(minAge);
    } else {
      // Handle the case when selectedDate is null or undefined
      return false; // Or you can throw an error or handle it differently
    }
  };

  const handlePaymentTabClick = (tabId, title) => {
    setActiveTab(tabId);
    if (formValues?.bankReceipt !== undefined) {
      setValue("bankReceipt", undefined);
    }
    if (tabId === 3) {
      setSubmitButtonActive(false);
    } else if (title === "stripe") {
      setSubmitButtonActive(true);
      setStripePublicKey(
        data.paymentGateways.find((item) => item.title === "stripe").publicKey
      );
      if (paymentIntentId === "") {
        const stripeMetaData = {
          username: `${formValues?.first_name} ${formValues?.last_name}`,
          email: formValues?.email,
          selectedRegMethod: selectedRegMethod,
          // quote: data?.regPack?.find((obj) => obj.id === formValues.quoteType).name,
        };

        const user = {
          username: `${formValues?.first_name} ${formValues?.last_name}`,
          email: formValues?.email,
        };

        const body = {
          productId:
            selectedRegMethod === "Invitation Code"
              ? formValues.product.id
              : formValues.quoteType,
          productName: formValues.productName,
          priceId: formValues.priceId,
          type: "register",
          billingType,
          partialPayment: formValues.quotePartialAmountStatus,
          totalAmount: formValues.totalAmount,
          metadata: stripeMetaData,
          user: user,
          email: formValues?.email,
          username: `${formValues?.first_name} ${formValues?.last_name}`,
        };

        stripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            if (res.status) {
              setClientSecret(res.client_secret);
              setPaymentIntentId(res.id);
              setStripeIntentStatus(res.status);
            } else {
              toast.error(res.description);
            }
          },
        });
      } else {
        const user = {
          username: `${formValues?.first_name} ${formValues?.last_name}`,
          email: formValues?.email,
        };
        const body = {
          productId: formValues.product.id,
          paymentIntentId: paymentIntentId,
          user: user,
        };
        updateStripeIntent.mutateAsync(body, {
          onSuccess: (res) => {
            setStripeIntentStatus(res.status);
          },
        });
      }
    } else if (title === "bank-transfer") {
      if (formValues?.bankReceipt === undefined) {
        setSubmitButtonActive(true);
      }
    } else {
      setSubmitButtonActive(true);
    }
    setValue("paymentType", tabId, { shouldValidate: true }); // Set the selected payment
    if (!data?.modStatus?.productStatus) {
      setValue("totalAmount", data?.regData?.toFixed(2)); // regAmount is added to total amount. If there is no product
    }
  };

  const handleNext = async () => {
    let isValid;
    if (
      activeStep === 3 &&
      !checkUsernameField.data?.data?.status &&
      checkUsernameField.data?.data?.field === "username" &&
      checkUsernameField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkEmailField.data?.data?.status &&
      checkEmailField.data?.data?.field === "email" &&
      checkEmailField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (activeStep === 1 && data?.regData) {
      setValue("pv", data?.regData);
      isValid = true;
    } else if (activeStep === 2 && selectedRegMethod === "Invitation Code") {
      // Disable the check for the invitation code
      isValid = true;
      // const isValidCoupn = await checkCouponValid(formValues?.invitation_code);
      // if (isValidCoupn) isValid = true;
    } else {
      isValid = await trigger();
    }
    if (!formValues?.mobile && activeStep === 2) {
      isValid = false;
      setError("mobile", { message: t("this_field_is_required") });
    }
    if (formValues?.password?.includes(" ") && activeStep === 3) {
      isValid = false;
      setError("password", { message: "password_cannot_contain_white_spaces" });
    }
    if (!isPhoneNumberValid && activeStep === 2) {
      isValid = false;
      setError("mobile", {
        message: t("min_length"),
      });
    }
    // age validation
    if (dateOfBirthField && !formValues?.date_of_birth && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("this_field_is_required"),
      });
    }
    if (!isMinAge?.status && isMinAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("ageValidation", { age: isMinAge?.ageLimit }),
      });
    } else if (isMaxAge?.status && isMaxAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("maxAgeLimit", { age: isMaxAge?.ageLimit }),
      });
    }
    //name validation
    if (activeStep === 2 && formValues?.first_name.length > 15) {
      isValid = false;
      setError("first_name", {
        message: t("max_length_is"),
      });
    }

    if (activeStep === 2 && formValues?.last_name?.length > 15) {
      isValid = false;
      setError("last_name", {
        message: t("max_length_is"),
      });
    }
    if (!formValues?.date_of_birth && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("this_field_is_required"),
      });
    }
    if (!isMinAge?.status && isMinAge?.ageLimit && activeStep === 2) {
      isValid = setError("date_of_birth", {
        message: t("ageValidation", { age: isMinAge?.ageLimit }),
      });
    }

    // Automatically select Stripe on step 3
    if (activeStep === 5) {
      handlePaymentTabClick(5, "stripe");
    }

    if (isValid) {
      let nextStep;
      if (activeStep === 2 && selectedRegMethod === "Invitation Code") {
        nextStep = 4;
      } else {
        nextStep = activeStep + 1;
      }

      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    if (paymentIntentId !== "") {
      const body = {
        stripeStatus: stripeIntentStatus,
        paymentIntentId: paymentIntentId,
      };
      cancelStripeIntent.mutateAsync(body, {
        onSuccess: (res) => {
          if (res.status === "canceled") {
            // clear stripe
            setPaymentIntentId("");
            setClientSecret("");
            setStripeIntentStatus("");
          }
        },
      });
    }
    setActiveTab("");
    let prevStep;
    if (activeStep === 4 && selectedRegMethod === "Invitation Code") {
      prevStep = 2;
    } else {
      prevStep = activeStep - 1;
    }

    animateStepTransition(prevStep);
    setActiveStep(prevStep);
    // below step is to clear the uploaded image & value
    if (activeStep === 4 || activeStep === 5) {
      setValue("bankReceipt", undefined);
    }
    if (file) {
      setFile(null);
    }
  };

  const handleSubmit = (paymentId, paymentIntent, paymentType) => {
    if (paymentType === "stripe") {
      formValues.stripeResponse = paymentIntent;
    }
    if (data?.sponsorData?.username) {
      setValue("placement", data?.sponsorData?.username);
      formValues.placement = data?.sponsorData?.username;
      setValue("referralId", data?.sponsorData?.id);
      formValues.referralId = data?.sponsorData?.id;
    }
    const payload = removeCustomFields(
      formValues,
      data?.contactInformation?.customField
    );
    replicaRegisterMutation.mutate(payload);
  };

  const handleProductClick = (
    productId,
    productName,
    pv,
    price,
    max_coverage_per_incident
  ) => {
    setValue("quoteType", productId);
    setValue("quoteName", productName);
    setValue("max_coverage_per_incident", max_coverage_per_incident);

    let totalAmount;
    if (billingType === "installment") {
      totalAmount = Math.round((price / 3) * 1.04);
    } else {
      totalAmount = Number(price) + JSON.parse(data?.regFee);
    }
    // setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
    // setValue(
    //   "product",
    //   { id: productId, name: productName, price: price },
    //   { shouldValidate: true }
    // );
    // setValue("pv", pv);
    setValue("totalAmount", totalAmount.toFixed(2));
    // setValue("regAmount", data?.regFee);
  };

  const handleClick = (productName, product) => {
    setValue("quoteType", product.packageId);
    setValue("productName", productName);
    setValue("quoteName", productName);
    setValue("billingType", billingType);
    setValue("max_coverage_per_incident", product.coverageAmount);

    const priceDetails = product.pricing[billingType];
    const priceId = priceDetails.priceId;
    setValue("priceId", priceId);

    const totalAmount = product.pricing[billingType].amount;
    setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
    setValue("totalAmount", totalAmount.toFixed(2));
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkEmailField.mutate({ field: name, value: value });
  };

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: name, value: value });
  };

  const handleCustomField = (id, value) => {
    let custom = { ...formValues.customFields };
    custom[id] = value;
    setValue("customFields", custom);
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      Upload.mutate(file);
    }
  };

  const handleCountry = (selectedCountry, statesData) => {
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    setValue("mobile", phone);
    setError("mobile", { message: "" });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isPhoneValid = (phone) => {
    // international phoneUtil validation is commented
    // return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));

    // Add minimum length validation
    return phone.length >= 7 && !isNaN(phone);
  };

  const isPhoneNumberValid = isPhoneValid(phoneNumber);

  const removeCustomFields = (payload, customField) => {
    customField.forEach((field) => {
      const key = field.code;
      delete payload[key];
    });
    return payload;
  };

  useEffect(() => {
    if (data?.modStatus?.productStatus === 0) {
      if (data?.regFee === 0) {
        setValue("pv", 0);
      } else {
        setValue("pv", data?.regFee);
      }
    }
  }, []);

  console.log("====reg steps============", data?.registrationSteps);

  const handleRegMethodSelect = (method) => {
    console.log("====method====", method);
    const totalAmount = Number(method.price) + JSON.parse(data?.regFee);
    setValue("totalAmt", `${CurrencyConverter(totalAmount, conversionFactor)}`);
    setValue("regMethod", method.id);
    setValue(
      "product",
      { id: method.id, name: method.name, price: method.price },
      { shouldValidate: true }
    );

    setValue("pv", method.pv);
    setValue("totalAmount", totalAmount.toFixed(2));
    setValue("regAmount", data?.regFee);
    if (method.name === "Invitation Code") {
      const filteredData = data?.registrationSteps?.filter(
        (obj) => obj.label != "vehicle_information"
      );
      setregSteps(filteredData);
    } else {
      setregSteps(data?.registrationSteps);
    }
    setselectedRegMethod(method.name);
  };

  const modalClosehandler = () => {
    setshowQuoteModal(false);
  };

  const openQuoteModal = async () => {
    const isvalid = await trigger();
    if (isvalid) {
      setshowQuoteModal(true);
    }
  };

  // const checkCouponValid = async (value) => {
  //   try {
  //     const response = await checkCoupn.mutateAsync({ cupn: value });
  //     if (!response.status) {
  //       setError("invitation_code", { message: t("invalid_code") });
  //     } else {
  //       return true;
  //     }
  //   } catch (error) {}
  // };

  const partialAmntCheckboxHandler = (e) => {
    const { checked } = e.target;
    let amount;
    console.log("==checked====", checked, formValues.totalAmount);
    if (checked) {
      setValue("quotePartialAmountStatus", checked);
      amount = (parseFloat(formValues.totalAmount) * data?.minRegPay) / 100;
      setValue("totalAmount", `${amount}`);
    } else {
      amount = data?.regPack?.find(
        (obj) => obj.id === formValues.quoteType
      ).price;
      setValue("totalAmount", `${amount}`);
    }
  };

  useEffect(() => {
    if (hasMounted) {
      handleBillingTypeChange(billingType);
    } else {
      setHasMounted(true); // Mark as mounted after the first render
    }
  }, [billingType]);

  const handleBillingTypeChange = (type) => {
    setBillingType(type);
    // Automatically select the first available product
    const firstProductName = Object.keys(STRIPE_PRODUCTS)[0];
    if (firstProductName) {
      const firstProduct = STRIPE_PRODUCTS[firstProductName];
      handleClick(firstProductName, firstProduct);
    }
  };

  console.log(
    "==requestQuoteStatus====",
    requestQuoteStatus,
    selectedRegMethod
  );
  const Phonecountries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["us"].includes(iso2);
  });

  return (
    <div className="main-content-regsiter" style={{ margin: "65px" }}>
      <div className="row justify-content-center pt-0 p-4" id="wizardRow">
        <div className="col-md-12 text-center">
          <div className="wizard-form py-4 my-2">
            <ul id="progressBar" className="progressbar px-lg-5 px-0">
              {steps.map((step, index) => (
                <li
                  key={`step-${index + 1}`}
                  id={`progressList-${index + 1}`}
                  className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${
                    data?.regPack === null && data?.regFee === 0
                      ? activeStep - 1 >= step.step
                        ? "active"
                        : ""
                      : activeStep >= step.step
                      ? "active"
                      : ""
                  }`}
                >
                  {t("step")}
                  {index + 1}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="animation">
        {activeStep === 1 && (data?.regFee !== 0 || data?.regPack !== null) && (
          <div className="row row_top justify-content-center" id="cardSection">
            <div className="col-lg-12 col-md-12">
              <div className="regsiter_step_1_view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="right_product_selection_bx">
                      <div
                        className="right_product_lsting_section"
                        style={{ textAlign: "center" }}
                      >
                        <div className="row replica">
                          {(data?.regData || data?.regData === 0) && (
                            <div className="col-md-4">
                              <div
                                className={`right_product_box card active-card`}
                                {...register("product")}
                              >
                                <div className="right_product_box_image">
                                  <img src={"/images/product1.jpg"} alt="" />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {/* {product?.name} */}
                                  </div>
                                  <div className="right_product_box__dsc">
                                    <strong id="price">{`${
                                      userSelectedCurrency?.symbolLeft
                                    } ${CurrencyConverter(
                                      data?.regData,
                                      conversionFactor
                                    )}`}</strong>
                                    <br />
                                    {"PV - "}
                                    {data?.regData}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                  onClick={() => handleNext()}
                                >
                                  {t("buy_now")}
                                </button>
                              </div>
                            </div>
                          )}
                          {data?.regMethods?.map((product, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className={`right_product_box card ${
                                  formValues.regMethod === product.id
                                    ? "active-card"
                                    : ""
                                }`}
                                {...register("regMethod", { required: true })}
                                // onClick={() =>
                                //   handleProductClick(
                                //     product.id,
                                //     product.name,
                                //     product.pairValue,
                                //     product.price
                                //   )
                                // }
                                onClick={() => handleRegMethodSelect(product)}
                              >
                                {product.name === "Invitation Code" ? (
                                  <>
                                    <div className="register_step_1_view_left_sec_head">
                                      Add Affiliate
                                    </div>
                                    <img
                                      src={codeimage}
                                      alt=""
                                      style={{ padding: "1em" }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="register_step_1_view_left_sec_head">
                                      Sell Coverage
                                    </div>
                                    <img
                                      src={invoiceImage}
                                      alt=""
                                      style={{ padding: "1em" }}
                                    />
                                  </>
                                )}
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {t(product.name)}
                                  </div>
                                </div>
                                {errors.regMethod && (
                                  <span className="error-message-validator">
                                    {t("this_field_is_required")}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <p>
                          As an Affiliate representative of One Llama, you may
                          sell Collision & Comprehensive mutuality coverage to
                          prospective customers, or you may add an Affiliate rep
                          seller to your network. It is not a requirement to buy
                          a coverage package to become an Affliate rep. If the
                          prospective customer only wants to purchase mutuality
                          coverage select Register. A Customer who purchases
                          mutuality coverage today can decide at a later date to
                          become an Affiliate Rep. Similarly, an Affiliate who
                          doesn't purchase coverage today may decide later to
                          purchase a coverage package for their vehicle(s). This
                          process here creates their account at One Llama,
                          either an Affiliate account or a Customer account.
                        </p>
                        <button
                          type="button"
                          className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                          onClick={handleNext}
                        >
                          {t("next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="row row_top justify-content-center form-business">
            <div style={{ display: "flex", justifyContent: "start" }}>
              <div className="col-lg-7">
                <div className="regsiter_step_1_view_left_sec_head">
                  {t("sponsor")}
                </div>
                <div className="regsiter_step_1_view_left_user_bx_1">
                  <div className="regsiter_step_1_view_left_user_bx_image_1">
                    <img
                      src={sponsorImage ?? "/images/user-profile.png"}
                      alt=""
                    />
                  </div>
                  <div className="regsiter_step_1_view_left_user_bx_txt">
                    <strong>{data?.sponsorData?.username}</strong>
                    <p>
                      {data?.sponsorData?.UserDetail?.fullName ? (
                        <>
                          {data?.sponsorData?.UserDetail?.fullName}
                          <br />
                          {data?.sponsorData?.email}
                        </>
                      ) : (
                        <Skeleton count={2} />
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                {data?.modStatus?.mlmPlan === "Binary" && (
                  <>
                    <div className="regsiter_step_1_view_left_sec_head">
                      {t("placement")}
                    </div>
                    <div className="regsiter_second_step_bx reg_select_pos">
                      <div className="regsiter_step_1_view_left_btn_row mt-0">
                        <label
                          className={`regsiter_step_1_view_left_btn ${
                            formValues.position === "L" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="position"
                            value={"L"}
                            {...register("position", {
                              required: true,
                            })}
                          />
                          {t("left")}
                        </label>
                        <label
                          className={`regsiter_step_1_view_left_btn ${
                            formValues.position === "R" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="position"
                            value={"R"}
                            {...register("position", {
                              required: true,
                            })}
                          />
                          {t("right")}
                        </label>
                      </div>
                      {errors["position"] && formValues.position === null && (
                        <span className="error-message-validator">
                          {t("this_field_is_required")}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="register_step_1_view_left_sec_head">
              {data?.registrationSteps &&
                (data.regPack === null && data.regFee === 0
                  ? t(data?.registrationSteps[activeStep - 2]?.label)
                  : data.regPack === null && data.regFee !== 0
                  ? t(data?.registrationSteps[activeStep - 1]?.label)
                  : t(data?.registrationSteps[activeStep - 1]?.label))}
            </div>

            <div className="regsiter_second_step_bx">
              {/* {selectedRegMethod === "Invitation Code" && (
                <div className="row mb-4">
                  <div className="col-md-6">
                    <label htmlFor="invitation_code">
                      {t("invitation_code")}
                      <span className="text-danger">٭</span>
                    </label>
                    <input
                      id="invitation_code"
                      name="invitation_code"
                      type="text"
                      className={`form-control ${
                        errors.invitation_code ? "error-field" : ""
                      }`}
                      {...register("invitation_code", {
                        required: {
                          value: true,
                          message: t("this_field_is_required"),
                        },
                        // pattern: {
                        //   value: /^[A-Za-z0-9]+$/,
                        //   message: t("invalid_format"),
                        // },
                      })}
                      onBlur={async (e) =>
                        await checkCouponValid(e.target.value)
                      }
                    />
                    {errors.invitation_code && (
                      <span className="error-message-validator">
                        {errors.invitation_code.message}
                      </span>
                    )}
                  </div>
                </div>
              )} */}
              <div className="row">
                {data?.contactInformation?.contactField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(`${item.code}`)}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" &&
                        item.code !== "state" &&
                        !item.options && (
                          <input
                            id={item.code}
                            name={item.code}
                            type="text"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              // pattern: {
                              //   value: /^[A-Za-z0-9]+$/,
                              //   message: t("invalid_format"),
                              // },
                            })}
                            onBlur={async () => await trigger(item.code)}
                          />
                        )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${
                              (!checkEmailField.data?.data?.status &&
                                checkEmailField.data?.data?.field === "email" &&
                                checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register("email", {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              setrequestQuote(false);
                              handleEmailCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <DatePickerComponent
                          className={`date-picker ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          date={selectedDob}
                          handleDateChange={(newDate) =>
                            handleDateChange(newDate, item)
                          }
                          isCalenderOpen={isCalenderOpen}
                          openCalender={openCalender}
                          closeCalender={closeCalender}
                        />
                      )}
                      {item.type === "number" && item.code === "mobile" && (
                        <PhoneInput
                          defaultCountry="us"
                          value={phoneNumber}
                          onChange={handlePhoneNumber}
                          countries={Phonecountries}
                        />
                      )}
                      {item.type === "number" && !(item.code === "mobile") && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                        />
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <Select
                          id={item.code}
                          name={item?.code}
                          className={`dropdown-common ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          options={genderOptions(item?.options)}
                          onChange={genderChange}
                          value={selectedGender}
                          isSearchable={false}
                        />
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <Select
                          isSearchable={true}
                          id={item.code}
                          name={item?.code}
                          className={`replica-reg-country ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                          value={countryList(item?.options).find(
                            (item) => item.value === selectedCountry
                          )}
                          options={countryList(item?.options)}
                          onChange={changeCountry}
                        />
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <Select
                          isSearchable={false}
                          id={item.code}
                          name={item?.code}
                          className={` ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          options={stateList(item?.options)}
                          onBlur={async () => await trigger(item.code)}
                          value={stateList(item?.options).find(
                            (state) => state?.value === selectedState
                          )}
                          onChange={changeState}
                        />
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {data?.contactInformation?.customField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {item.value}{" "}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" && !item.options && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="text"
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            // pattern: {
                            //   value: /^[A-Za-z0-9]+$/,
                            //   message: t("invalid_format"),
                            // },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "email" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="email"
                            className={`form-control ${
                              (!checkEmailField.data?.data?.status &&
                                checkEmailField.data?.data?.field === "email" &&
                                checkEmailField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleCustomField(item.id, inputValue);
                            }}
                          />
                          {!checkEmailField.data?.data?.status &&
                            checkEmailField.data?.data?.field === "email" &&
                            checkEmailField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type="date"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              validate: (value) => validateAge(value, t),
                            })}
                            onChange={async (e) => {
                              const inputValue = e.target.value; // Get the current value of the input field
                              handleCustomField(item.id, inputValue);
                              await trigger(item.code);
                            }}
                          />
                          {errors.date_of_birth && (
                            <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.type === "textarea" && (
                        <textarea
                          id={item.code}
                          name={item.code}
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "number" && (
                        <input
                          id={item.code}
                          name={item.code}
                          type="number"
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        />
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.title}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <select
                          id={item.code}
                          name={item.code}
                          className={`form-control ${
                            errors[item.name] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCountry(e.target.value, item.options);
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          <option>{t("select_the_country")}</option>
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <select
                          id={item.code}
                          name={item.name}
                          className={`form-control ${
                            errors[item.name] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={async (e) => {
                            const inputValue = e.target.value; // Get the current value of the input field
                            handleCustomField(item.id, inputValue);
                            await trigger(item.code);
                          }}
                        >
                          {states?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  {!requestQuoteStatus && selectedRegMethod == "Quote" ? (
                    <button
                      type="button"
                      className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                      onClick={openQuoteModal}
                    >
                      {t("request_a_quote")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                      onClick={handleNext}
                    >
                      {t("next")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div className="row row_top justify-content-center" id="cardSection">
            <div className="col-lg-12 col-md-12">
              <div className="regsiter_step_1_view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="right_product_selection_bx">
                      <div className="regsiter_step_1_view_left_sec_head">
                        <h2>Pick your Plan and Payment Arrangement</h2>
                      </div>
                      {selectedRegMethod !== "Invitation Code" && (
                        <div>
                          <p>
                            Choose the coverage level that best reflects the
                            current value of your car. Choose a payment plan
                            that works for your budget.
                          </p>
                        </div>
                      )}
                      <div className="right_product_lsting_section">
                        <div className="row">
                          <div className="product-selection">
                            <div className="toggle-buttons">
                              <button
                                className={`toggle-button ${
                                  billingType === "installment" ? "active" : ""
                                }`}
                                onClick={() => setBillingType("installment")}
                              >
                                Monthly
                              </button>
                              <button
                                className={`toggle-button ${
                                  billingType === "every6Month" ? "active" : ""
                                }`}
                                onClick={() => setBillingType("every6Month")}
                              >
                                Every 6 Months
                              </button>
                            </div>
                          </div>

                          {/* Product Cards */}
                          {Object.keys(STRIPE_PRODUCTS).map((productName) => {
                            const product = STRIPE_PRODUCTS[productName];
                            const priceDetails = product.pricing[billingType];

                            return (
                              <div className="col-md-6 mb-4" key={product.id}>
                                <div
                                  className={`right_product_box card ${
                                    formValues.quoteType === product.packageId
                                      ? "active-card"
                                      : ""
                                  }`}
                                  {...register("quoteType", { required: true })}
                                  onClick={() =>
                                    handleClick(productName, product)
                                  }
                                  style={{
                                    borderRadius: "20px",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                                  }}
                                >
                                  <div
                                    className="right_product_box__dtails"
                                    style={{ textAlign: "center" }}
                                  >
                                    <div className="right_product_box__dsc">
                                      <div className="quote-card-content">
                                        <span className="quote-name">
                                          {productName}
                                        </span>
                                        <span className="quote-description">
                                          {product.description}
                                        </span>
                                        <div className="quote-details">
                                          <span className="quote-coverage">
                                            Coverage Level: <br />
                                            <span className="max_coverage_per_incident">
                                              {product.coverageAmount} per
                                              incident
                                            </span>
                                          </span>
                                          <span className="quote-price">
                                            {billingType === "every6Month"
                                              ? "6 Month Cost:"
                                              : "Monthly Cost:"}{" "}
                                            <br />
                                            <span className="quote-price-ka">
                                              {userSelectedCurrency?.symbolLeft}
                                              {CurrencyConverter(
                                                priceDetails.amount.toFixed(2),
                                                conversionFactor
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {/* skeleton */}
                          {data?.regPack === undefined && (
                            <div className="col-md-4">
                              <div className="right_product_box card">
                                <div className="right_product_box_image">
                                  <Skeleton width={125} height={125} />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    <Skeleton width={125} />
                                    <Skeleton width={80} count={2} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {errors.product && (
                          <span className="error-message-validator">
                            {t("this_field_is_required")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-dark text-white float-start back mt-4 rounded-3"
                  onClick={handleBack}
                  style={{ background: "#954cea" }}
                >
                  {t("back")}
                </button>
                <button
                  type="button"
                  className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                  onClick={handleNext}
                >
                  {t("next")}
                </button>
              </div>
            </div>
          </div>
        )}
        {activeStep === 4 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_step_1_view_left_sec_head">
              {selectedRegMethod === "Invitation Code"
                ? t("Create the Affiliate Account")
                : data?.registrationSteps &&
                  ((data.regPack === null || data.regPack.length === 0) &&
                  (data.regData === 0 || data.regData === null)
                    ? t(data?.registrationSteps[activeStep - 2]?.label)
                    : (data.regPack === null || data.regPack.length === 0) &&
                      (data.regData !== 0 || data.regData !== null)
                    ? t(data?.registrationSteps[activeStep - 1]?.label)
                    : t(data?.registrationSteps[activeStep - 1]?.label))}
            </div>
            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.loginInformation.map((item, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor={item.code}>
                        {t(item.code)} <span className="text-danger">٭</span>
                      </label>
                      {item.type === "text" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={item.type}
                            className={`form-control ${
                              (!checkUsernameField.data?.data?.status &&
                                checkUsernameField.data?.data?.field ===
                                  "username" &&
                                checkUsernameField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginUsernameValidator(item, t)
                            )}
                            onChangeCapture={async (e) => {
                              await handleUsernameCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkUsernameField.data?.data?.status &&
                            checkUsernameField.data?.data?.field ===
                              "username" &&
                            checkUsernameField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("username_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "password" && (
                        <>
                          <input
                            id={item.code}
                            name={item.code}
                            type={showPassword ? "text" : "password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginPasswordValidator(item, t)
                            )}
                            onBlur={async () => await trigger(item.code)}
                          />
                          <InputAdornment
                            position="end"
                            className="replica-eye"
                          >
                            <IconButton
                              onClick={handleShowPassword}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                          <PasswordChecklist
                            rules={passwordRules(item.validation)}
                            minLength={item.validation.minLength}
                            value={watch("password", "")}
                            className="mb-3"
                          />
                          <label htmlFor={"confirmPassword"}>
                            {t("confirmPassword")}{" "}
                            <span className="text-danger">٭</span>
                          </label>
                          <input
                            id={"confirmPassword"}
                            name={"confirmPassword"}
                            type={"password"}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${
                              errors["confirmPassword"] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register("confirmPassword", {
                              required: {
                                message: t("this_field_is_required"),
                              },
                              validate: (value) =>
                                value === getValues("password") ||
                                t("passwordNotMatch"),
                            })}
                            onBlur={async () =>
                              await trigger("confirmPassword")
                            }
                          />
                        </>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {t(errors[item.code].message)}
                        </span>
                      )}
                      {errors["confirmPassword"] &&
                        item.code !== "username" && (
                          <span className="error-message-validator">
                            {errors["confirmPassword"].message}
                          </span>
                        )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  {selectedRegMethod !== "Invitation Code" && (
                    <div className="regsiter_form_box_tandc">
                      <label className="d-flex" htmlFor="box">
                        <input
                          name={"termsAndCondition"}
                          type="checkbox"
                          id="box"
                          style={{ marginRight: "5px" }}
                          {...register("termsAndCondition", {
                            required: true,
                          })}
                        />
                      </label>
                    <Link
                      className="pop_terms_btn"
                      to={termsandconditions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("iAcceptTermsAndConditions")}
                      <span className="text-danger"> ٭ </span>
                      {errors["termsAndCondition"] &&
                        formValues["termsAndCondition"] === false && (
                          <span className="error-message-validator">
                            *{t("required")}
                          </span>
                        )}
                    </Link>   
                    </div>
                  )}

                  <div className="regsiter_form_box_tandc">
                    <label className="d-flex" htmlFor="box">
                      <input
                        name={"proceduresChecked"}
                        type="checkbox"
                        id="box"
                        style={{ marginRight: "5px" }}
                        {...register("proceduresChecked", {
                          required: true,
                        })}
                      />
                    </label>
                    <Link
                      className="pop_terms_btn"
                      to={procedures}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(" I ACCEPT ONE LLAMA POLICIES AND PROCEDURES")}
                      <span className="text-danger"> ٭ </span>
                      {errors["proceduresChecked"] &&
                        formValues["proceduresChecked"] === false && (
                          <span className="error-message-validator">
                            *{t("required")}
                          </span>
                        )}
                    </Link>
                  </div>

                  {selectedRegMethod === "Invitation Code" && (
                    <>
                      <div className="regsiter_form_box_tandc">
                        <label className="d-flex" htmlFor="box">
                          <input
                            name={"agreementChecked"}
                            type="checkbox"
                            id="box"
                            style={{ marginRight: "5px" }}
                            {...register("agreementChecked", {
                              required: true,
                            })}
                          />
                        </label>
                        <Link
                          className="pop_terms_btn"
                          to={pdfFile}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t(" I ACCEPT ONE LLAMA AFFILIATE AGREEMENT")}
                          <span className="text-danger"> ٭ </span>
                          {errors["agreementChecked"] &&
                            formValues["agreementChecked"] === false && (
                              <span className="error-message-validator">
                                *{t("required")}
                              </span>
                            )}
                        </Link>
                      </div>

                      <div className="regsiter_form_box_tandc">
                        <label className="d-flex" htmlFor="box">
                          <input
                            name={"compensationChecked"}
                            type="checkbox"
                            id="box"
                            style={{ marginRight: "5px" }}
                            {...register("compensationChecked", {
                              required: true,
                            })}
                          />
                        </label>
                        <Link
                          className="pop_terms_btn"
                          to={compensation}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t(" I ACCEPT ONE LLAMA AFFILIATE COMPENSATION PLAN")}
                          <span className="text-danger"> ٭ </span>
                          {errors["compensationChecked"] &&
                            formValues["compensationChecked"] === false && (
                              <span className="error-message-validator">
                                *{t("required")}
                              </span>
                            )}
                        </Link>
                      </div>

                      <div className="regsiter_form_box_tandc">
                        <label className="d-flex" htmlFor="box">
                          <input
                            name={"teslaChecked"}
                            type="checkbox"
                            id="box"
                            style={{ marginRight: "5px" }}
                            {...register("teslaChecked", {
                              required: true,
                            })}
                          />
                        </label>
                        <Link
                          className="pop_terms_btn"
                          to={tesla}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t(
                            " I ACCEPT ONE LLAMA TESLA BONUS PROMOTION TERM AND CONDITION"
                          )}
                          <span className="text-danger"> ٭ </span>
                          {errors["teslaChecked"] &&
                            formValues["teslaChecked"] === false && (
                              <span className="error-message-validator">
                                *{t("required")}
                              </span>
                            )}
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 5 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_second_step_bx">
              <div className="regsiter_overview_box">
                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t("product_and_sponsor")}</strong>
                </div>
                <div className="row">
                  {data?.modStatus?.productStatus !== 0 && (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="product">{t("product")}</label>
                      <strong id="product">{formValues?.product?.name}</strong>
                    </div>
                  )}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="sponsor">{t("sponsor")}</label>
                    <strong id="sponsor">{data?.sponsorData?.username}</strong>
                  </div>
                  {formValues?.quoteName && (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="pv">{t("QuoteType")}</label>
                      <strong id="pv">{formValues?.quoteName}</strong>
                    </div>
                  )}
                  {/* <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="price">{t("price")}</label>
                    <strong id="price">{`${userSelectedCurrency?.symbolLeft
                    } ${CurrencyConverter(
                      formValues?.totalAmount,
                      conversionFactor
                    )}`}</strong>
                  </div> */}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="total">{t("totalAmount")}</label>
                    <strong id="total">{`${
                      userSelectedCurrency?.symbolLeft
                    } ${CurrencyConverter(
                      formValues?.totalAmount ?? data?.regData,
                      conversionFactor
                    )}`}</strong>
                  </div>

                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label>Payment Plan</label>
                    <strong>
                      {billingType === "installment"
                        ? "Monthly"
                        : billingType === "every6Month"
                        ? "Every Six Months"
                        : billingType}
                    </strong>
                  </div>

                  {billingType === "installment" && (
                    <>
                      <div className="col-md-4 mb-3 regsiter_overview_col">
                        <label>Pay Today</label>
                        <strong>
                          {`${
                            userSelectedCurrency?.symbolLeft
                          } ${CurrencyConverter(
                            formValues?.totalAmount
                              ? formValues?.totalAmount
                              : data?.regData,
                            conversionFactor
                          )}`}
                        </strong>
                      </div>

                      {/* <div className="col-md-4 mb-3 regsiter_overview_col">
                        <label>Total Remaining</label>
                        <strong>
                          {`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(
                            formValues?.totalAmount
                              ? formValues?.totalAmount
                              : data?.regData,
                            conversionFactor
                          )}`}{" "}
                          X 2
                        </strong>
                      </div> */}
                    </>
                  )}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                </div>

                <div className="row">
                  {Object.entries(formValues)?.map(
                    ([key, value]) =>
                      ![
                        "product",
                        "pv",
                        "regAmount",
                        "termsAndCondition",
                        "totalAmt",
                        "paymentType",
                        "customFields",
                        "bankReceipt",
                        "referralId",
                        "totalAmount",
                        "username",
                        "password",
                        "country",
                        "state",
                        "confirmPassword",
                        "position",
                        "regMethod",
                        "quoteType",
                        "partialQuoteAmount",
                        "quotePartialAmountStatus",
                        "quoteName",
                        "proceduresChecked",
                        "agreementChecked",
                        "compensationChecked",
                        "teslaChecked",
                        "priceId",
                        "productName",
                        "billingType",
                      ].includes(key) && (
                        <div
                          className="col-md-4 mb-3 regsiter_overview_col"
                          key={key}
                        >
                          <label htmlFor={`input-${key}`}>{t(key)}</label>
                          <strong id={`input-${key}`}>{value}</strong>
                        </div>
                      )
                  )}
                  {formValues?.customField?.map(([key, value]) => (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor={`input-${key}`}>{t(key)}</label>
                      <strong id={`input-${key}`}>{value}</strong>
                    </div>
                  ))}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[3]?.label}`)}</strong>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="username">{t("username")}</label>
                    <strong id="username">{formValues.username}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="password">{t("password")}</label>
                    <strong id="password">********</strong>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    {t("back")}
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    {t("next")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 6 && (
          <div className="row row_top justify-content-center form-business">
            <div className="col-lg-12 col-md-12" id="payment">
              <div className="payment_section_tab">
                <div className="regsiter_step_1_view_left_sec_head">
                  {data?.registrationSteps &&
                    (data.regPack === null && data.regFee === 0
                      ? t(data?.registrationSteps[activeStep - 2]?.label)
                      : data.regPack === null && data.regFee !== 0
                      ? t(data?.registrationSteps[activeStep - 1]?.label)
                      : t(data?.registrationSteps[activeStep - 1]?.label))}
                  <br />
                  <strong>
                    {selectedRegMethod === "Invitation Code" && (
                      <p>
                        {t(labels.affiliate_payment_message)}
                      </p>
                    )}
                    {selectedRegMethod !== "Invitation Code" && (
                      <div className="registration-wrapper">
                        <div className="step-information">
                          <p>
                            Complete your purchase of One Llama Mutuality
                            Coverage for Collision & Comprehensive!
                          </p>
                        </div>
                        <div className="product-card">
                          <div className="card-content">
                            <div className="card-details">
                              {formValues?.quoteName && (
                                <div className="col-md-4 mb-3 regsiter_overview_col stepfiiii">
                                  <label htmlFor="pv">{t("QuoteType")}</label>
                                  <strong id="pv">
                                    {formValues?.quoteName}
                                  </strong>
                                </div>
                              )}
                              {formValues?.max_coverage_per_incident && (
                                <span className="coverage-level">
                                  Coverage Level:{" "}
                                  <span className="coverage-amount">
                                    {formValues?.max_coverage_per_incident}
                                  </span>
                                </span>
                              )}
                              <span className="cost-info">
                                {t(
                                  billingType === "installment"
                                    ? "Monthly Price"
                                    : "6 Month Cost"
                                )}
                                :
                                <span className="cost-value">
                                  {`${
                                    userSelectedCurrency?.symbolLeft
                                  } ${CurrencyConverter(
                                    formValues?.totalAmount
                                      ? formValues?.totalAmount
                                      : data?.regData,
                                    conversionFactor
                                  )}`}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <br></br>
                    {t(
                      billingType === "installment"
                        ? "Payment Amount"
                        : "totalAmount"
                    )}
                    :{" "}
                    {`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(
                      formValues?.totalAmount ?? data?.regFee,
                      conversionFactor
                    )}`}
                  </strong>
                </div>

                <div className="row">
                  <div className="col-md-9 ps-0 skai">
                    <div
                      className="tab-content p-3 border mb-5 payment__tab_cnt"
                      id="nav-tabContent"
                    >
                      {(formValues.paymentType === undefined ||
                        formValues.paymentType === "") && (
                        <p>
                          <strong>{t("preferredPaymentPlaceholder")}</strong>
                        </p>
                      )}

                      {data?.paymentGateways?.map((tab) => (
                        <div
                          key={tab.id}
                          className={`tab-pane fade ${
                            activeTab === tab.id ? "show active" : ""
                          }`}
                          id={`nav-${tab.id}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${tab.id}-tab`}
                        >
                          {tab.title === "free-joining" && (
                            <p>
                              <strong>
                                {t("freeJoinPlaceHolder", {
                                  type: "registration",
                                })}
                              </strong>
                            </p>
                          )}
                          {tab.title === "bank-transfer" && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <label
                                    htmlFor="fileUpload"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {t("file_upload")}
                                  </label>
                                  <input
                                    id="bankReciept"
                                    type="file"
                                    placeholder=""
                                    className="form-control"
                                    style={{ height: "33px" }}
                                    name="fileUpload"
                                    onChange={handleFileChange}
                                  />
                                  {fileResponse?.success &&
                                    formValues?.bankReceipt !== undefined && (
                                      <div style={{ color: "green" }}>
                                        {t(fileResponse?.success)}
                                      </div>
                                    )}
                                  {fileResponse?.error && (
                                    <div style={{ color: "red" }}>
                                      {t(fileResponse?.error)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button
                                  href="#"
                                  className="btn btn-sm btn-primary mt-3"
                                  onClick={handleUpload}
                                  disabled={
                                    Upload.status === "loading" ||
                                    formValues?.bankReceipt ||
                                    document.getElementById("bankReciept")
                                      ?.value === "" ||
                                    file === null
                                  }
                                >
                                  {Upload.status === "loading"
                                    ? "Uploading..."
                                    : t("upload")}
                                </button>
                              </div>
                              {Upload?.data?.status === true &&
                                formValues?.bankReceipt !== undefined && (
                                  <>
                                    <img
                                      src={`${API_BASE_URL}${Upload?.data?.data?.file?.path}`}
                                      alt="receipt"
                                      style={{
                                        width: "160px",
                                        height: "160px",
                                        padding: "5px",
                                      }}
                                    />
                                    <button
                                      className="recipt_checkout_address_btn"
                                      onClick={handleDeleteBankReciept}
                                    >
                                      <i className="fa fa-xmark"></i>
                                    </button>
                                    <div style={{ color: "green" }}>
                                      {Upload?.data?.message}
                                    </div>
                                  </>
                                )}
                              {Upload?.data?.status === false && (
                                <span className="error-message-validator">
                                  {Upload?.data?.message}
                                </span>
                              )}
                            </div>
                          )}
                          {tab.title === "stripe" && clientSecret !== "" && (
                            <p>
                              <Stripe
                                clientSecret={clientSecret}
                                handleSubmit={handleSubmit}
                                publicKey={stripePublicKey}
                              />
                            </p>
                          )}
                          {tab.title === "paypal" && (
                            <MyPayPalOrderButton
                              currency={userSelectedCurrency?.code}
                              price={formValues.totalAmount}
                              handleSubmit={handleSubmit}
                              paymentId={tab.id}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.paymentType && (
                  <span className="error-message-validator">*Required</span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-dark text-white float-start back rounded-3 bg-color-back"
                onClick={handleBack}
              >
                {t("back")}
              </button>
              {activeTab !== 5 && (
                <button
                  type="submit"
                  className="btn text-white float-end submit-button rounded-3 bg-color-info"
                  disabled={submitButtonActive}
                  onClick={handleSubmit}
                >
                  {t("finish")}
                </button>
              )}
            </div>
          </div>
        )}

        {data?.quoteRequestInformation && (
          <QuoteModal
            showModal={showQuoteModal}
            close={modalClosehandler}
            fields={data?.quoteRequestInformation}
            t={t}
            formValues={formValues}
            setshowQuoteModal={setshowQuoteModal}
            setrequestQuote={setrequestQuote}
          />
        )}
      </div>
      {replicaRegisterMutation.status === "loading" && (
        <div className="reg-submit-loader-overlay">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            style={{ margin: "auto", display: "block", shapeRendering: "auto" }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <path
              fill="none"
              stroke="#7b33c2"
              stroke-width="8"
              stroke-dasharray="42.76482137044271 42.76482137044271"
              d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
              stroke-linecap="round"
              style={{ transform: "scale(0.8)", transformOrigin: "50px 50px" }}
            >
              <animate
                attributeName="stroke-dashoffset"
                repeatCount="indefinite"
                dur="1s"
                keyTimes="0;1"
                values="0;256.58892822265625"
              ></animate>
            </path>
          </svg>
        </div>
      )}
    </div>
  );
};

export default ReplicaRegisterForm;
