import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";
import { forgetPasswordValidator, passwordRules } from "../../Validator/register";
import { useForm } from "react-hook-form";

export const ForgotPasswordForm = () => {
  const {
    register,
    trigger,
    watch,
    formState: { errors },
  } = useForm();
  const formValues = watch();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Add logging for hash key
  useEffect(() => {
    console.log("Hash key from params:", params.hashKey);
  }, [params.hashKey]);

  const verifyHashKey = ApiHook.CallVerifyForgotPassword({
    hash: params.hashKey,
  });

  // Add logging for API response
  useEffect(() => {
    console.log("Verify hash key response:", verifyHashKey);
    if (verifyHashKey.isError) {
      console.error("API Error:", verifyHashKey.error);
    }
    setIsLoading(false);
  }, [verifyHashKey.data, verifyHashKey.isError]);

  const passwordMutation = ApiHook.CallChangeForgotPassword();

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    console.log("Submit attempted with values:", {
      password: formValues?.password,
      confirmPassword,
    });

    // Password validation logic
    if (formValues?.password !== confirmPassword) {
      setValidationError("Passwords do not match");
      return;
    }

    // Add loading state and error handling
    setIsLoading(true);
    passwordMutation.mutate(
      { password: formValues?.password, hash: params.hashKey },
      {
        onSuccess: (res) => {
          console.log("Password change response:", res);
          if (res.status) {
            toast.success(res.data);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            toast.error(res?.data?.description);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        },
        onError: (error) => {
          console.error("Password change error:", error);
          toast.error("An error occurred while changing the password");
        },
        onSettled: () => {
          setIsLoading(false);
        }
      }
    );

    setValidationError("");
  };

  // Handle invalid or expired hash key
  useEffect(() => {
    if (verifyHashKey?.data?.status === false) {
      console.log("Invalid hash key detected:", verifyHashKey?.data);
      toast.error(verifyHashKey?.data?.data?.description);
      navigate("/login");
    }
  }, [verifyHashKey?.data?.status, navigate]);

  if (isLoading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    verifyHashKey.data?.status && (
      <div>
        <section className="loginSection">
          <div className="container centerDiv">
            <div className="loginBgImg"></div>
            <div className="row justify-content-center">
              <div className="col-md-6 logincredDetail">
                <div className="loginBg">
                  <div className="loginFormSec p-5">
                    <div className="loginLogo">
                      <img src="https://admin.onellama.com/assets/images/logo-dark.png" alt="" />
                    </div>
                    <p>{t("newPassword")}</p>
                    <div className="loginFormSec mt-5">
                      <p className="text-start text-dark">
                        {`Username: ${verifyHashKey.data?.data?.data?.username || 'Not available'}`}
                      </p>
                      <div className="passwordInput">
                        <label htmlFor="password">{t("newPassword")}</label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          className={`form-control ${errors.password ? "error-field" : ""}`}
                          placeholder={t("newPassword")}
                          {...register(
                            "password",
                            verifyHashKey?.data?.data?.data?.passwordPolicy !== null
                              ? forgetPasswordValidator(verifyHashKey?.data?.data?.data, t)
                              : undefined
                          )}
                          onBlur={async () => await trigger("password")}
                        />
                        {errors.password && (
                          <p className="text-danger">{errors.password.message}</p>
                        )}
                      </div>
                      <div className="passwordInput">
                        <label htmlFor="confirmPassword">
                          {t("confirmPassword")}
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-control"
                          placeholder={t("confirmPassword")}
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                        {verifyHashKey?.data?.data?.data?.passwordPolicy && (
                          <PasswordChecklist
                            rules={passwordRules(verifyHashKey?.data?.data?.data?.passwordPolicy)}
                            minLength={verifyHashKey?.data?.data?.data?.passwordPolicy?.minLength}
                            value={watch("password", "")}
                          />
                        )}
                      </div>
                      {validationError && (
                        <p className="text-danger">{validationError}</p>
                      )}
                    </div>
                    <div className="loginBtn">
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : t("change")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default ForgotPasswordForm;