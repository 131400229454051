import React, { useState, useEffect } from "react";
import { ApiHook } from "../../hooks/apiHook";

const PlanBRegister = () => {
    const [dashboardCheck, setDashboardCheck] = useState(false);
    const [username, setUsername] = useState(null);
    const rightSection = ApiHook.CallDashboardRight(
        dashboardCheck,
        setDashboardCheck
    );

    useEffect(() => {
        // Check if the rightSection data is available and set the username
        if (rightSection?.data?.userProfile?.username) {
            setUsername(rightSection.data.userProfile.username);
        }
    }, [rightSection]);

    // Construct the URL using the environment variable
    const iframeUrl = username
        ? `${process.env.REACT_APP_REGISTRATION_APP_URL}${username.toLowerCase()}`
        : null;
    console.log("iframeUrl", iframeUrl);

    return (
        iframeUrl && (
            <div style={{ width: "100%", height: "100vh", border: "none" }}>
                <iframe
                    src={iframeUrl}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        overflow: "hidden",
                    }}
                    allowFullScreen
                ></iframe>
            </div>
        )
    );
};

export default PlanBRegister;
